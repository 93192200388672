import '../../../node_modules/swiper/swiper-bundle.min.css'
import Swiper, { Pagination } from 'swiper';
new Swiper('.swiper', {
    modules: [Pagination],
});
import '../../../node_modules/swiper/swiper-bundle.min.js'





export default {
    name: "Expert",
    data() {
        return {
            window: window,
            mySwiper: {},
            StartX: 0,
            EndX: 0,
            // 主任医师数组1（每个数组最多10个医生 ）
            swiperList: [{
                    name: "杨志祥",
                    post: "主任医师",
                    rightTop: "",
                    src: require("../../assets/yangzhixiang.png"),
                    text: "",
                },
                {
                    name: "袁国安",
                    post: "副主任医师",
                    rightTop: "",
                    src: require("../../assets/yuanguoan.png"),
                    text: "",
                },
                {
                    name: "陈修义",
                    post: "主任医师",
                    rightTop: "",
                    src: require("../../assets/chenxiuyi.png"),
                    text: "",
                },
                {
                    name: "尹梓贻",
                    post: "副主任医师",
                    rightTop: "",
                    src: require("../../assets/yinziyi.png"),
                    text: "",
                },
                {
                    name: "周宗贵",
                    post: "副主任医师",
                    rightTop: "",
                    src: require("../../assets/zhouzonggui.png"),
                    text: "",
                },
                {
                    name: "蒋学",
                    post: "副主任医师",
                    rightTop: "",
                    src: require("../../assets/jiangxue.png"),
                    text: "",
                },
                {
                    name: "苏斌",
                    post: "主任医师",
                    rightTop: "",
                    src: require("../../assets/subin.png"),
                    text: "",
                },
                {
                    name: "文亚雄",
                    post: "主任医师",
                    rightTop: "",
                    src: require("../../assets/wenyaxiong.png"),
                    text: "",
                },
                {
                    name: "李跟亮",
                    post: "副主任医师",
                    rightTop: "",
                    src: require("../../assets/ligenliang.png"),
                    text: "",
                },

            ],
            doctorList: [{
                    name: "郭杨",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/guoyang.png"),
                    text: "",
                },
                {
                    name: "张于锋",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/zhangyufeng.png"),
					text: "",
                },
                {
                    name: "梁峰",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/liangfeng.png"),
					text: "",
                },
                {
                    name: "李富春",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/lifuchun.png"),
					text: "",
                },
                {
                    name: "戴尚进",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/daishangjin.png"),
					text: "",
                },
                {
                    name: "李世涛",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/lishitao.png"),
					text: "",
                },
                {
                    name: "黄广程",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/huangguangcheng.png"),
					text: "",
                },
                {
                    name: "陈燕华",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/chenyanhua.png"),
					text: "",
                },
                {
                    name: "刘举利",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/liujuli.png"),
					text: "",
                },
                {
                    name: "张章",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/zhangzhang.png"),
					text: "",
                },
                {
                    name: "夏正法",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/xiazhengfa.png"),
					text: "",
                },
                {
                    name: "史后彬",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/shihoubin.png"),
					text: "",
                },
                {
                    name: "李雪亭",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/lixueting.png"),
					text: "",
                },
                {
                    name: "杨昕",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/yangxin.png"),
					text: "",
                },
                {
                    name: "鲁少康",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/lushaokang.png"),
					text: "",
                },
                {
                    name: "林威特",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/linweite.png"),
					text: "",
                },
                {
                    name: "张卓",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/zhangzhuo.png"),
					text: "",
                },

                {
                    name: "杨勇",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/yangyong.png"),
					text: "",
                },
                {
                    name: "贺俊",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/hejun.png"),
					text: "",
               },
                {
                    name: "郎振图",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/langzhentu.png"),
					text: "",
                },
                {
                    name: "孙锐",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/sunrui.png"),
					text: "",
                },
                {
                    name: "张新进",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/zhangxinjin.png"),
					text: "",
                },
                {
                    name: "强志顺",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/qiangzhishun.png"),
					text: "",
                },
                {
                    name: "张桥",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/zhangqiao.png"),
					text: "",
                },
                {
                    name: "李进",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/lijin.png"),
					text: "",
                },
                {
                    name: "曹国荣",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/caoguorong.png"),
					text: "",
                },
                {
                    name: "卢春涛",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/luchuntao.png"),
					text: "",
                },

                {
                    name: "史元培",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/shiyuanpei.png"),
					text: "",
                },
                {
                    name: "李兴国",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/lixingguo.png"),
					text: "",
                },
                {
                    name: "王卜宣",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/wangbuxuan.png"),
					text: "",
                },
                {
                    name: "朱世伟",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/zhushiwei.png"),
					text: "",
                },
                {
                    name: "夏振强",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/xiazhenqiang.png"),
					text: "",
                },
                {
                    name: "李中先",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/lizhongxian.png"),
					text: "",
                },
                {
                    name: "伍湘涛",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/wuxiangtao.png"),
					text: "",
                },
                {
                    name: "王荣",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/wangrong.png"),
					text: "",
                },
                {
                    name: "韩伟",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/hanwei.png"),
					text: "",
                },
                {
                    name: "罗继森",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/luojiseng.png"),
					text: "",
                },

                {
                    name: "刘敬",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/liujing.png"),
					text: "",
                },
                {
                    name: "冯广飞",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/fengguangfei.png"),
					text: "",
                },
                {
                    name: "尹卓维",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/yinzhuowei.png"),
					text: "",
                },
                {
                    name: "张鹏程",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/zhangpengcheng.png"),
					text: "",
                },
                {
                    name: "罗道龙",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/luodaolong.png"),
					text: "",
                },
                {
                    name: "张辛宁",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/zhangxinning.png"),
					text: "",
                },
                {
                    name: "畅昭",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/changshao.png"),
					text: "",
                },
                {
                    name: "杨勇华",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/yangyonghua.png"),
					text: "",
                },
                {
                    name: "林迪生",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/lindisheng.png"),
					text: "",
                },
                {
                    name: "孟文祥",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/mengwenxiang.png"),
					text: "",
                },
                {
                    name: "刘尚彦",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/liushangyan.png"),
					text: "",
                },
                {
                    name: "姜印",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/jiangyin.png"),
					text: "",
                },
                {
                    name: "于艳",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/yuyan.png"),
					text: "",
                },
                {
                    name: "王建辉",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/wangjianhui.png"),
					text: "",
                },
                {
                    name: "伍盛为",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/wushengwei.png"),
					text: "",
                },
                {
                    name: "徐乐",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/xule.png"),
					text: "",
                },
                {
                    name: "周杏翠",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/zhouxingcui.png"),
					text: "",
                },
                {
                    name: "朱家麟",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/zhujialin.png"),
					text: "",
                },
                {
                    name: "周金鑫",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/zhoujinxin.png"),
					text: "",
                },
                {
                    name: "周健",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/zhoujian.png"),
					text: "",
                },
                {
                    name: "王亚昆",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/wangyakun.png"),
					text: "",
                },
                {
                    name: "王锦",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/wangjin.png"),
					text: "",
                },
                {
                    name: "王春梅",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/wangchunmei.png"),
					text: "",
                },
                {
                    name: "宋卫昆",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/songweikun.png"),
					text: "",
                },
                {
                    name: "邱红杰",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/qiuhongjie.png"),
					text: "",
                },
                {
                    name: "牛东兴",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/niudongxing.png"),
					text: "",
                },
                {
                    name: "李自克",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/lizike.png"),
					text: "",
                },
                {
                    name: "李志杰",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/lizhijie.png"),
					text: "",
                },
                {
                    name: "李盼盼",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/lipanpan.png"),
					text: "",
                },
                {
                    name: "李冬丽",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/lidongli.png"),
					text: "",
                },
                {
                    name: "黄晨",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/huangchen.png"),
					text: "",
                },
                {
                    name: "郭永芳",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/guoyongfang.png"),
					text: "",
                },
                {
                    name: "冯文力",
                    post: "植发医师",
                    rightTop: "",
                    src: require("../../assets/fengwenli.png"),
					text: "",
                },
            ],
            //底部
            bottomList: [{
                    url: require("../../assets/16.svg"),
                    text: " 2005年创立企业",
                },
                {
                    url: require("../../assets/32.svg"),
                    text: " 全国直营连锁",
                },
                {
                    url: require("../../assets/13.svg"),
                    text: " 13年连续参加世界植发大会",
                },
                {
                    url: require("../../assets/600.svg"),
                    text: " 600位医护团队",
                },
                {
                    url: require("../../assets/300.svg"),
                    text: " 16类植养方式",
                },
            ],



        };
    },
    components: {
        // Swiper,
    },

    methods: {
        swiperClick() {
            this.$nextTick(() => {
                for (let index = 0; index < this.$refs.pagination.children.length; index++) {
                    const element = this.$refs.pagination.children[index];
                    element.className = 'paginationBox'
                    console.log(element);
                }
                this.$refs.pagination.children[this.mySwiper.realIndex].className += ' selectPaginationBox'
            })

        },
        goToSwiper(index, event) {
            if (event == 'pagination') {}
            this.mySwiper.slideToLoop(index, 1000, false)
        },

        createSwiper() {
            let that = this
            this.mySwiper = new Swiper('.swiper', {
                direction: 'horizontal', // 垂直切换选项
                loop: true, // 循环模式选项

                // 如果需要分页器
                pagination: {
                    el: '.swiper-pagination',
                },
                preventClicksPropagation: false,

                // 如果需要滚动条
                scrollbar: {
                    el: '.swiper-scrollbar',
                },
                on: {


                    touchEnd: function(params, event) {

                        that.swiperClick()

                    }
                }

            })

        }
    },
    mounted() {
        this.createSwiper()
        this.$refs.pagination.children[0].className += ' selectPaginationBox'
    }
};